<template>
  <div class="py-4 px-0 pt-4 pb-0 mt-2 mb-0">
    <v-row>
      <v-col md="3" cols="6" class="py-0 ma-0">
        <v-select :items="options" item-text="text" item-value="value" v-model="field.type" :rules="defaultRules" outlined dense label="Tipo"
          @change="changeType(field.type)"></v-select>
      </v-col>

      <v-col md="4" cols="6" class="py-0">
        <v-text-field outlined dense v-model="field.label" label="Nome" :rules="defaultRules" :disabled="isLockedField" />
      </v-col>

      <v-col md="3" cols="6" class="py-0">
        <v-text-field outlined dense v-model="field.name" label="Identificador" disabled v-if="!this.new" />
      </v-col>

      <v-col cols="2" class="d-flex justify-end py-0 pa-6" v-if="!isLockedField">
        <v-btn
          color="error"
          fab
          x-small
          dark
          title="Remover campo"
          @click="removeField()"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0 my-0" xs="12" md="5" lg="3" cols="12">
        <v-switch class="py-0 my-0" v-if="!isLockedField" v-model="field.validator_show" label="Exibir para cliente"
          @change="$emit('update-validator')"></v-switch>
      </v-col>

      <v-col class="py-0 my-0" xs="12" md="5" lg="3" cols="12">
        <v-switch class="py-0 my-0" v-if="!isLockedField" v-model="field.required" label="Obrigatório"
          @change="$emit('update-required')"></v-switch>
      </v-col>
    </v-row>

    <v-row v-if="isListType">
      <v-col md="12" class="py-0 my-0">
        <v-row v-for="(option, index_option) in field.options" :key="index_option" no-gutters>
          <v-col md="5" class="pr-2">
            <v-text-field outlined dense v-model="option.value" :rules="defaultRules" label="valor" />
          </v-col>

          <v-col md="5" class="px-2">
            <v-text-field outlined dense v-model="option.text" :rules="defaultRules" label="texto" />
          </v-col>

          <v-col cols="2" class="d-flex justify-end py-0 pa-6" v-if="!isLockedField && field.options.length > 1">
            <v-btn
              color="error"
              fab
              x-small
              dark
              title="Remover campo"
              @click="removeOption(index_option)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="py-0 my-0 d-flex justify-end">
        <v-btn class="ma-2" color="primary" small @click="addOption">
          <v-icon left>mdi-playlist-plus</v-icon>
          <span>Nova linha</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: ["field", "index", "options"],
  data() {
    return {
      defaultRules: [v => !!v || "Campo obrigatório"],
    };
  },
  computed: {
    ...mapState("solicitationType", ["new"]),
    isLockedField() {
      return this.field.type === 'requester' || this.field.type === 'company'
    },
    isListType() {
      return this.field.type === 'selectList'
    }
  },
  methods: {
    removeField() {
      this.$emit("removeField", this.index);
    },
    addOption() {
      this.field.options.push({
        value: "",
        text: ""
      });
    },
    changeType(field) {
      if (field === "plans" || field === "ddd" || field === "packages") {
        this.$emit("update-options", field);
      }
      this.$emit("update-type", this.index);
    },
    removeOption(index) {
      this.field.options.splice(index, 1)
    }
  }
};
</script>
