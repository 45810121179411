<template>
  <div>
    <v-sheet
        class="mx-auto mt-2"
        elevation="6"
    >
      <v-col cols="12">
        <div v-if="type !== 'edit'">
          <v-card-title
            :loading="loadingFields"
            class="subtitle-1 text--primary pt-0 px-0"
          >
            Campos Obrigatórios
          </v-card-title>
          <v-card-text
            v-for="(item, index) in field_list_required"
            :key="index"
            class="ma-0 pa-0 "
          >
            <editorFieldComponet
              :options="fieldTypes"
              item-text="text"
              item-value="value"
              :field="item"
              :index="index"
              @removeField="removeField($event)"
              @update-validator="updateValidator"
              @update-required="updateRequired"
              @update-type="updateType($event)"
              @update-options="updateOptions($event)"
            />
            <v-divider></v-divider>
          </v-card-text>
        </div>
        <v-card-title
          :loading="loadingFields"
          class="subtitle-1 text--primary pt-5 px-0"
        >
          Campos Adicionais
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text
          v-for="(item, index) in field_list"
          :key="index"
          class="ma-0 pa-0 "
        >
          <editorFieldComponet
            :options="fieldTypes"
            item-text="text"
            item-value="value"
            :field="item"
            :index="index"
            @removeField="removeField($event)"
            @update-validator="updateValidator"
            @update-required="updateRequired"
            @update-type="updateType($event)"
            @update-options="updateOptions($event)"
          />
          <v-divider></v-divider>
        </v-card-text>

        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            @click="addField()"
            variant="outlined"
            elevation="5"
            small
            color="primary"
          >
            Adicionar campo
            <v-icon right> mdi-plus-circle-outline </v-icon>
          </v-btn>
        </v-col>
      </v-col>
    </v-sheet>
  </div>
</template>

<script>
import editorFieldComponet from "./editorFieldComponent";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    editorFieldComponet
  },
  props: ["type"],
  data() {
    return {
      loadingFields: true,
      options: [],
      fieldTypes: [],
      field_list_required: [
        {
          name: "requester",
          type: "requester",
          label: "Solicitante*",
          options: [],
          validator_show: false,
          required: true
        },
        {
          name: "company",
          type: "company",
          label: "Empresa*",
          options: [],
          validator_show: false,
          required: true
        }
      ],
      field_list: []
    };
  },
  async mounted() {
    await this.loadFieldTypes().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.fieldTypes = this.field_types.data;

    this.fieldTypes = this.fieldTypes.map(item => {
      if (item.value === "requester" || item.value === "company") {
        item.disabled = true;
      }
      return item;
    });

    if (!this.new) {
      this.field_list = this.fields;
    } else {
      this.setFieldsRequired(this.field_list_required);
    }
    this.loadingFields = false;
  },
  computed: {
    ...mapGetters("solicitationType", ["getFieldTypesNew", "getFieldTypes"]),
    ...mapState("solicitationType", ["new", "fields", "field_types"])
  },
  methods: {
    ...mapMutations({ setFields: "solicitationType/setFields" }),
    ...mapMutations({ setFieldsRequired: "solicitationType/setFieldsRequired" }),
    ...mapMutations({
      setFieldsRequired: "solicitationType/setFieldsRequired"
    }),
    ...mapActions("solicitationType", ["loadFieldTypes"]),

    updateValidator() {
      this.setFields(this.field_list);
    },
    updateRequired() {
      this.setFields(this.field_list);
    },
    addField() {
      this.field_list.push({
        label: "",
        name: "",
        type: "",
        options: [],
        validator_show: false,
        required: false
      });
      this.setFields(this.field_list);
    },
    removeField(item) {
      let new_fields = [];
      for (let f in this.field_list) {
        if (f != item) {
          new_fields.push(this.field_list[f]);
        }
      }
      this.field_list = new_fields;
      this.setFields(this.field_list);
    },

    updateType(index) {
      let field = this.field_list[index];

      if (field.type == "selectList") {
        field.options = [];
      }
      if (field.type == "requester") {
        field.name = "requester";
      }
      if (field.type == "company") {
        field.name = "company";
      }
      this.setFields(this.field_list);
    },
    updateOptions(option) {
      this.fieldTypes = this.fieldTypes.map(field => {
        if (field.value === option) {
          field.disabled = true;
        }
        return field;
      });
    },
    change() {
      this.setFields(this.field_list);
    }
  }
};
</script>
